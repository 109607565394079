import { Category, MappingConfig } from '@dxp/akeneo-client';
import {
  CatalogActions,
  IProductOrReferenceData,
  PdpsPayloadAction,
  ProductByIdentifierPayloadAction,
  ReferenceEntityPayloadAction,
  ReferenceEntityStateProperty,
} from '@dxp/catalog';
import {
  ContentPageBuilder,
  ContentTypeMap,
  ContentfulDataActions,
  EPageContentTypes,
  NavigationsActions,
  TypeCeAkeneoSliderFields,
  TypeCeDownloadShortlistFields,
  TypeCeTwoBigProductTeaserBoxesFields,
  TypeCeVideoListFields,
  TypePage,
  TypePageFields,
  TypeProductListingFields,
  contentful,
} from '@dxp/content';
import { LanguageSwitchActions, PlatformWebappPageSize, toUppercaseLocale } from '@dxp/core';
import { FrontifyActions } from '@dxp/frontify-client';
import { Entry } from 'contentful';
import { GetStaticPaths, GetStaticProps } from 'next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { END } from 'redux-saga';
import PageHead from '../components/page-head/page-head';
import i18nConfig from '../next-i18next.config';
import { HeaderActions, HeaderThemes, SagaStore, useAppDispatch, wrapper } from '../redux';
import { productMappingConfig, productModelMappingConfig, referenceEntitiesConfig } from '../utils';

export const ContentPage = (props: { locale: string; page: TypePage }) => {
  const router = useRouter();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(HeaderActions.setLayout(HeaderThemes.ON_LIGHT));
  }, [dispatch]);

  return (
    <>
      {props.page?.fields?.meta && (
        <PageHead
          title={props.page.fields.meta?.title?.length > 0 ? props.page.fields.meta.title : props.page.fields.pageTitle}
          description={props.page.fields.meta?.description}
          ogTitle={props.page.fields.meta?.og_title}
          ogDescription={props.page.fields.meta?.og_description}
          ogImage={props.page.fields.meta?.og_image}
          ogImageAlt={props.page.fields.meta?.og_image_alt}
          twitterTitle={props.page.fields.meta?.twitter_title}
          twitterDescription={props.page.fields.meta?.twitter_description}
          twitterImage={props.page.fields.meta?.twitter_image}
          twitterImageAlt={props.page.fields.meta?.twitter_image_alt}
          noIndex={props.page.fields.meta?.noindex}
        />
      )}
      <ContentPageBuilder page={props.page} locale={router.locale || i18nConfig.i18n.defaultLocale} />
    </>
  );
};

export default ContentPage;

export const getStaticProps: GetStaticProps = wrapper.getStaticProps(
  (store) =>
    async ({ locale, params, preview = false, previewData = false }) => {
      locale =
        locale === i18nConfig.i18n.defaultLocale
          ? (i18nConfig.fallbackLng as string)
          : locale || (i18nConfig.fallbackLng as string);

      const slug: string =
        Array.isArray(params?.slug) && !!params?.slug.length ? params.slug.join('/') : (params?.slug as string);

      const page: Entry<TypePageFields> | null = await contentful.getPage({
        slug,
        locale: locale ?? (i18nConfig.fallbackLng as string),
        preview,
      });

      const slugs: { [locale: string]: string } | null = await contentful.getLocalizedSlugs(
        page?.sys.id || '',
        EPageContentTypes.PAGE
      );
      if (slugs !== null) {
        await store.dispatch(LanguageSwitchActions.push(slugs));
      }

      if (!page) {
        return {
          notFound: true,
        };
      } else {
        const ceWithAdditionalAkeneoData = <T,>(contentElement: Entry<T>) =>
          contentElement?.sys?.contentType?.sys?.id &&
          ContentTypeMap.has(contentElement.sys.contentType.sys.id) &&
          !!ContentTypeMap.get(contentElement.sys.contentType.sys.id)?.additionalAkeneoData;

        const ceWithAdditionalContentfulData = <T,>(contentElement: Entry<T>) =>
          contentElement?.sys?.contentType?.sys?.id &&
          ContentTypeMap.has(contentElement.sys.contentType.sys.id) &&
          !!ContentTypeMap.get(contentElement.sys.contentType.sys.id)?.additionalContentfulData;

        const ceWithAdditionalFrontifyData = <T,>(contentElement: Entry<T>) =>
          contentElement?.sys?.contentType?.sys?.id &&
          ContentTypeMap.has(contentElement.sys.contentType.sys.id) &&
          !!ContentTypeMap.get(contentElement.sys.contentType.sys.id)?.additionalFrontifyData;

        if (page.fields.contentElements?.some(ceWithAdditionalAkeneoData)) {
          const contentElements = page.fields.contentElements?.filter(ceWithAdditionalAkeneoData);

          for (const contentElement of contentElements) {
            switch (contentElement.sys.contentType.sys.id) {
              case 'productListing': {
                const pdpType = (contentElement.fields as TypeProductListingFields).type;
                const categories: Array<string> = (
                  (contentElement.fields as TypeProductListingFields).categories as Array<Category>
                ).map((category: Category) => category.code);
                const productsPayloadAction: PdpsPayloadAction = {
                  pdpType,
                  categories,
                  locale: locale ? toUppercaseLocale(locale) : toUppercaseLocale(i18nConfig.fallbackLng as string),
                  scope: 'digital',
                  mappingConfig: pdpType === 'productModel' ? productModelMappingConfig : productMappingConfig,
                  onlyParent: pdpType === 'productModel',
                };
                await store.dispatch(CatalogActions.categoriesRequested({}));
                await store.dispatch(CatalogActions.pdpsByCategoryRequested(productsPayloadAction));
                break;
              }
              case 'ceTwoBigProductTeaserBoxes': {
                const leftProduct = (contentElement.fields as TypeCeTwoBigProductTeaserBoxesFields).leftProduct;
                const rightProduct = (contentElement.fields as TypeCeTwoBigProductTeaserBoxesFields).rightProduct;

                const ceProducts: IProductOrReferenceData[] = [leftProduct, rightProduct];

                const ceProductQueryConfig: ProductByIdentifierPayloadAction[] = ceProducts.map(
                  (ceProduct: IProductOrReferenceData) => ({
                    type: ceProduct.type === 'product-model' ? 'productModel' : 'product',
                    identifier: ceProduct.sku || (ceProduct.code as string),
                    locale: locale ? toUppercaseLocale(locale) : toUppercaseLocale(i18nConfig.fallbackLng as string),
                    mappingConfig:
                      ceProduct.type === 'product-model'
                        ? (productModelMappingConfig as MappingConfig)
                        : (productMappingConfig as MappingConfig),
                  })
                );

                await ceProductQueryConfig.forEach(async (query) => {
                  await store.dispatch(CatalogActions.productByIdentifierRequested(query));
                });

                break;
              }
              case 'ceAkeneoSlider': {
                const items: IProductOrReferenceData[] = (contentElement.fields as TypeCeAkeneoSliderFields).items;

                if (items && items.length > 0) {
                  const type = items[0].type;

                  if (type === 'product' || type === 'product-model') {
                    const ceProductQueryConfig: ProductByIdentifierPayloadAction[] = items.map(
                      (item: IProductOrReferenceData) => ({
                        type: item.type === 'product-model' ? 'productModel' : 'product',
                        identifier: item.sku || (item.code as string),
                        locale: locale
                          ? toUppercaseLocale(locale)
                          : toUppercaseLocale(i18nConfig.fallbackLng as string),
                        mappingConfig:
                          item.type === 'product-model'
                            ? (productModelMappingConfig as MappingConfig)
                            : (productMappingConfig as MappingConfig),
                      })
                    );

                    await ceProductQueryConfig.forEach(async (query) => {
                      await store.dispatch(CatalogActions.productByIdentifierRequested(query));
                    });
                    break;
                  } else if (type === 'reference-entity' && items[0].entity) {
                    const query: ReferenceEntityPayloadAction = {
                      code: items[0].entity,
                      stateProperty: ReferenceEntityStateProperty.akeneoslider,
                      codes: items.map((item: IProductOrReferenceData) => item.code),
                      locale: locale ? toUppercaseLocale(locale) : toUppercaseLocale(i18nConfig.fallbackLng as string),
                      mappingConfig: referenceEntitiesConfig[items[0].entity as keyof typeof referenceEntitiesConfig],
                    };

                    await store.dispatch(CatalogActions.referenceEntityRequested(query));
                  }
                }

                break;
              }
              default:
                break;
            }
          }
        }

        if (page.fields.contentElements?.some(ceWithAdditionalContentfulData)) {
          const contentElements = page.fields.contentElements?.filter(ceWithAdditionalContentfulData);

          for (const contentElement of contentElements) {
            switch (contentElement.sys.contentType.sys.id) {
              case 'ceNewsTeaser': {
                await store.dispatch(
                  ContentfulDataActions.articlesRequested({
                    locale,
                    page: 1,
                    pageSize: 3,
                  })
                );
                break;
              }
              case 'ceVacancyListing': {
                await store.dispatch(ContentfulDataActions.vacanciesRequested(locale));
                break;
              }
              case 'ceVideoList': {
                await store.dispatch(
                  FrontifyActions.libraryDefault({
                    libraryId: (contentElement.fields as TypeCeVideoListFields).libraryId,
                    libraryConfig: [
                      {
                        id: (contentElement.fields as TypeCeVideoListFields).libraryId,
                        name: (contentElement.fields as TypeCeVideoListFields).title,
                        metadataFields: [],
                        pageSize: PlatformWebappPageSize,
                      },
                    ],
                    pageSize: PlatformWebappPageSize,
                  })
                );

                break;
              }
              default:
                break;
            }
          }
        }

        if (page.fields.contentElements?.some(ceWithAdditionalFrontifyData)) {
          const contentElements = page.fields.contentElements?.filter(ceWithAdditionalFrontifyData);

          for (const contentElement of contentElements) {
            store.dispatch(
              FrontifyActions.assetByPreviewUrlRequested((contentElement.fields as TypeCeDownloadShortlistFields).items)
            );
          }
        }

        await store.dispatch(NavigationsActions.fetchAll(locale));
        store.dispatch(END);
        await (store as SagaStore).sagaTask?.toPromise();

        return {
          props: {
            ...(await serverSideTranslations(locale ?? '', ['common', 'account', 'catalog'], i18nConfig)),
            page,
            preview,
            previewData,
          },
          revalidate: parseInt(process.env['NX_PAGE_EQUIPMENT_REVALIDATE'] ?? '3600'),
        };
      }
    }
);

export const getStaticPaths: GetStaticPaths = async ({ locales }) => {
  locales = locales?.filter((locale) => locale !== i18nConfig.i18n.defaultLocale);

  let paths: Array<{ params: { slug: Array<string> }; locale: string }> = [];

  for (const locale of locales || []) {
    const slugSet = await contentful.getAllPageSlugs(locale);
    paths = paths.concat(slugSet.map((slug) => ({ params: { slug: slug.split('/') }, locale })));
  }

  return {
    paths,
    fallback: 'blocking',
  };
};
