import Head from 'next/head';
import { useContext } from 'react';
import { PageConfigContext } from '@dxp/core';

export interface PageHeadProps {
  title: string;
  description?: string;
  ogTitle?: string;
  ogDescription?: string;
  ogImage?: string;
  ogImageWidth?: string;
  ogImageHeight?: string;
  ogImageAlt?: string;
  twitterTitle?: string;
  twitterDescription?: string;
  twitterImage?: string;
  twitterImageAlt?: string;
  noIndex?: boolean;
}

export const PageHead = (props: PageHeadProps) => {
  const title = props.title;
  const {
    description = '',
    ogTitle = '',
    ogDescription = '',
    ogImage = '',
    ogImageWidth = '',
    ogImageHeight = '',
    ogImageAlt = '',
    twitterDescription = '',
    twitterTitle = '',
    twitterImage = '',
    twitterImageAlt = '',
    noIndex = false,
  } = props || {};

  const pageConfig = useContext(PageConfigContext);

  return (
    <Head>
      <title>{title.length > 0 ? title : pageConfig?.metadataSiteName}</title>
      <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
      {description.length > 0 && <meta name="description" content={description} key={'description'} />}
      <meta property="og:site_name" content={pageConfig?.metadataSiteName} key={'og_site_name'} />
      <meta property="og:title" content={ogTitle ? ogTitle : title} key={'og_title'} />
      <meta property="og:type" content={'website'} key={'og_type'} />
      {(ogDescription.length > 0 || description.length > 0) && (
        <meta property="og:description" content={ogDescription ? ogDescription : description} key={'og_description'} />
      )}
      {!!ogImage.length && <meta property="og:image" content={ogImage} key={'og_image'} />}
      {!!ogImageWidth.length && <meta property="og:image:width" content={ogImageWidth} key={'og_image_width'} />}
      {!!ogImageHeight.length && <meta property="og:image:height" content={ogImageHeight} key={'og_image_height'} />}
      {!!ogImageAlt.length && <meta property="og:image:alt" content={ogImageAlt} key={'og_image_alt'} />}
      <meta property="twitter:card" content={'summary'} key={'twitter_card'} />
      <meta property="twitter:site" content={pageConfig?.metadataSiteName} key={'twitter_site'} />
      {(!!twitterTitle.length || !!ogTitle.length || !!title.length) && (
        <meta
          property="twitter:title"
          content={twitterTitle ? twitterTitle : ogTitle ? ogTitle : title}
          key={'twitter_title'}
        />
      )}
      {(!!twitterDescription.length || !!ogDescription.length || !!description.length) && (
        <meta
          property="twitter:description"
          content={twitterDescription ? twitterDescription : ogDescription ? ogDescription : description}
          key={'twitter_description'}
        />
      )}
      {(!!twitterImage.length || !!ogImage.length) && (
        <meta property="twitter:image" content={twitterImage ? twitterImage : ogImage} key={'twitter_image'} />
      )}
      {(!!twitterImageAlt.length || !!ogImageAlt?.length) && (
        <meta
          property="twitter:image:alt"
          content={twitterImageAlt ? twitterImageAlt : ogImageAlt}
          key={'twitter_alt'}
        />
      )}
      {noIndex && <meta property="robots" content={'noindex'} key={'robots'} />}
    </Head>
  );
};

export default PageHead;
